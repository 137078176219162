<template>
  <div>
    <div class="card card-custom mb-5 mt-sm-5">
      <div class="card-body">
        <div v-if="filtersConfig.length" class="row">
          <div class="col-md-6">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="computedTypesFilter.title"
                :value="computedTypesFilter.options.filter(el => filters.type_id.includes(el.id))"
                :options="computedTypesFilter.options"
                :multiple="true"
                @select="filters.type_id.push($event.id)"
                @remove="filters.type_id = filters.type_id.filter(el => el !== $event.id)"
            />
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </div>
    <div v-if="documentsList.length">
      <div v-for="document in documentsList" class="card card-custom document-card border-left-success mb-3">
        <div class="card-body">
          <div class="row d-flex justify-content-between align-items-center">
            <span class="col-md-1 fal fa-file-contract font-size-h1"/>
            <div class="col-md-5 d-flex flex-column align-items-center w-50">
              <div>{{ $t('label.type') }}</div>
              <div class="font-size-lg font-weight-bolder">{{ document.type }}</div>
            </div>
            <div class="col-md-4 d-flex flex-column align-items-center">
              <div>{{ $t('label.apartment') }}</div>
              <router-link
                  v-if="document.apartment.id"
                  :to="`/apartments/${document.apartment.id}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a :href="`/apartments/${document.apartment.id}`"
                   class="mb-1 font-size-lg font-weight-bolder"
                   @click="navigate"
                >
                  {{ document.apartment.name }}
                </a>
              </router-link>
              <div v-else class="font-size-lg font-weight-bolder">{{ document.apartment.crm_id }}</div>
            </div>
            <div class="col-md-2 cursor-pointer" @click="downloadDocument(document.id)">{{ $t('btn.click_to_download') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!documentsList.length && !loading" class="card card-custom">
      <div class="card-body">
        <div class="lead text-center mt-5">
          {{ $t('description.list_is_empty') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';
import BootstrapVueTable from '@/components/elements/tables/BootstrapVueTable';
import DatetimePickerFormGroup from '@/components/elements/form-groups/DatetimePickerFormGroup';
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';

export default {
  components: {
    BootstrapVueTable,
    DatetimePickerFormGroup,
    InputFormGroup,
    MultiselectFormGroup
  },
  data: () => ({
    filters: {
      type_id: [],
      apartment_crm_id: []
    },
    filtersConfig: {},
    filtersLoaded: false,
    loading: true
  }),
  async created() {
    const DEBOUNCE_TIME = 400;
    this.debouncedUpdate = _.debounce(this.debouncedUpdate, DEBOUNCE_TIME);
  },
  async beforeMount() {
    this.getDocuments();
    this.filtersConfig = await this.$store.dispatch('tableStore/GET_FILTERS', 'documents');
  },
  computed: {
    ...mapGetters({
      documentsList: 'documentsStore/ITEMS_LIST'
    }),
    computedTypesFilter() {
      return this.filtersConfig.length ? this.filtersConfig.find(el => el.code === 'type') : null;
    },
    computedFilters() {
      let result = {};

      for (let key in this.filters) {
        if (this.filters.hasOwnProperty(key) && this.filters[key]) {
          if (Array.isArray(this.filters[key]) && !this.filters[key].length) continue;
          if (!Array.isArray(this.filters[key]) && typeof this.filters[key] === 'object' && !this.filters[key].from && !this.filters[key].to) continue;
          result[key] = this.filters[key];
        }
      }

      return result;
    }
  },
  watch: {
    filtersConfig(data) {
      if (data) {
        setTimeout(() => {
          this.filtersLoaded = true;
        }, 100);
      }
    },
    filters: {
      handler() {
        if (this.filtersLoaded) {
          this.getDocuments();
        }
      },
      deep: true
    },
  },
  methods: {
    async downloadDocument(documentID) {
      await this.$store.dispatch('documentsStore/DOWNLOAD', documentID);
    },
    debouncedUpdate(key, value) {
      if (!this.filtersLoaded) return false;
      this.$set(this.filters, key, value);
    },
    async getDocuments() {
      this.loading = true;
      await this.$store.dispatch('documentsStore/GET_LIST', this.computedFilters);
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$store.commit('documentsStore/CLEAR_ITEMS_LIST');
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  position: relative;
  margin-bottom: 0 !important;

  & .form-group__label {
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    z-index: 2;
  }
}

.document-card {
  border-left: 5px solid;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>